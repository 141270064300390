.DoneChapter {
  /* Add your styles here */
  background-color: white;
  padding: calc(var(--factorPlatform) * .5) calc(var(--factorPlatform) * 3);
  border-radius: calc(var(--factorPlatform) * 2);

  .titleDoneChapter {
    font-size: var(--font_size_little);
    font-weight: 600;
    color: red;
    margin: var(--factorPlatform) 0;
  }

  .yesDoneChapter {
    font-size: var(--font_size_little);
    font-weight: 600;
    background-color: var(--mainColorPlatForm);
    color: white;
    width: calc(var(--factorPlatform) * 15);
    aspect-ratio: 15/1;
    border: none;
    border-radius: calc(var(--factorPlatform) * 2);
    cursor: pointer;
  }
}