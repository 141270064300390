.contentSurvey {
  width: 100%;
  /* height: 100%; */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* overflow: auto; */
  /* opacity: 0.5; */
  /* position: fixed;
    top: 0;
    right: 0;
    display: flex; */
  --sub_background_color: white;
  /* --half_transparent: #4f6fcbe0; */
  --border_survey: .5vmin solid var(--sub_background_color);
}


.contentSurvey>.head {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: 1vmin;
}

.contentSurvey>.head.result {
  @media screen and (max-width: 800px) {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    padding: 1vmin;
    align-content: center;
    align-items: center;
  }
}

.contentSurvey>.head>.title {
  color: var(--sub_main_background_color);
  font-size: var(--font_size_big);
}

.contentSurvey>.head>.send {
  cursor: pointer;
  padding: 1vmin;
  user-select: none;

  border: var(--border_survey);
  border-radius: var(--border_radius);
  background-color: var(--sub_main_background_color);
  color: var(--sub_background_color);
}



.contentSurvey>.questions {
  background-color: var(--main_background_color);
}

.contentSurvey>.materials,
.contentSurvey>.questions {
  border-radius: var(--border_radius);
  width: max-content;
  margin: auto;
  padding: 1vmin;
  margin-top: 2vh;
  overflow: auto;
  max-width: 100%;
}

.contentSurvey>.materials {
  display: flex;
  gap: 1vw;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}


.contentSurvey>.materials>.material,
.contentSurvey>.questions>.question {
  border: var(--border_survey);
  border-radius: var(--border_radius);
  padding: 1vmin;
  cursor: default;
  user-select: none;
  background-color: var(--main_background_color);
  font-size: var(--font_size_normal);
}

.contentSurvey>.materials>.material {
  cursor: pointer;
  color: var(--sub_background_color);
}

.contentSurvey>.questions>.question {
  color: var(--sub_main_background_color);
  background-color: var(--sub_background_color);
  margin-bottom: 2vh;
  box-shadow: 0 .5vmin 1vmin 0 var(--main_background_color);
  font-weight: 900;
}

.contentSurvey>.questions>.question>.buttons {
  display: flex;
  gap: 1vw;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.contentSurvey>.questions>.question>.buttons>button {
  cursor: pointer;
  background-color: transparent;
  color: black;
  padding: 1vmin;
  border: var(--border_survey);
  border-color: var(--sub_main_background_color);
  border-radius: var(--border_radius);
  user-select: none;
}

.contentSurvey>.materials>.material.select,
.contentSurvey>.questions>.question>.buttons>button.select {
  border: var(--border_survey);
  border-radius: var(--border_radius);
  background-color: var(--sub_main_background_color);
  color: var(--sub_background_color);
}