.exam {
    width: 100%;
    height: 100dvh;
    display: grid;
    grid-template-rows: auto 1fr auto;
    align-content: space-between;
    justify-items: stretch;
    align-items: normal;
}

.exam>.exam_title {
    width: 100%;
    background-color: var(--main_background_color);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: space-around;
    align-items: center;
}

.exam>.exam_title p {
    font-weight: bolder;
    font-size: var(--font_size_little);
    color: var(--color_white);
}

.exam>.content {
    overflow: auto;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-around;
    align-items: center;
}

.exam>.content>.question {
    background-color: var(--main_background_color);
    min-width: 70%;
    border-radius: var(--border_radius);
    padding: 1vmin;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: stretch;
    gap: 1vh;
    margin: 1vmin;
}

.exam>.content>.question>img {
    max-width: 90vw;
    /* margin: auto auto 1vh auto; */
    align-self: center;
}

.exam>.content>.question>p {
    text-wrap: balance;
    text-align: right;
    color: var(--color_white);
    font-size: var(--font_size_little);
    margin-bottom: 1vh;
}

.exam>.content>.question>.chooses {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: space-around;
    align-items: center;
    gap: 1vmin;
}

.exam>.content>.question>.chooses>.choose {
    position: relative;
    border: .2vmin solid var(--color_white);
    padding: .5vmin calc(var(--font_size_little) * 1.5) .5vmin .5vmin;
    border-radius: var(--border_radius);
    cursor: pointer;
}

.exam>.content>.question>.chooses>.choose.active {
    background-color: var(--sub_main_background_color);
}

.exam>.content>.question>.chooses>.choose:hover {
    border-color: var(--sub_main_background_color);
}

.exam>.content>.question>.chooses>.choose>p {
    font-size: var(--font_size_little);

}

.exam>.content>.question>.chooses>.choose>:first-child {
    position: absolute;
    top: 0;
    right: 0;
    background-color: var(--color_white);
    height: var(--font_size_little);
    line-height: var(--font_size_little);
    width: var(--font_size_little);
}

.exam>.content>.question>.chooses>.choose>:nth-child(2) {
    /* padding-right: calc(var(--font_size_little) *2 ); */
    color: var(--color_white);
}

.exam>.footer {
    background-color: var(--main_background_color);
    padding: .5vmin 4vw;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-content: space-between;
    justify-items: stretch;
    align-items: normal;
}

.exam>.footer>.arrows>button,
.exam>.footer>button {
    background-color: var(--sub_main_background_color);
    border: .2vmin solid var(--color_white);
    padding: .5vmin;
    cursor: pointer;
    color: var(--color_white);
    height: 100%;
    border-radius: var(--border_radius);
}

.exam>.footer>.arrows {
    justify-self: start;
}

.exam>.footer>.arrows>button.disabled {
    opacity: .5;
    cursor: no-drop;
}

.exam>.footer>.arrows>button:hover,
.exam>.footer>button:hover {
    border-color: var(--sub_main_background_color);
}

.exam>.footer>button {
    justify-self: center;
}

.exam>.footer>.questions {
    justify-self: end;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    overflow-x: auto;
    max-width: 100%;
    justify-content: flex-start;
}

.exam>.footer>.questions>button {
    justify-self: end;
    background-color: var(--main_background_color);
    color: var(--color_white);
    border: .2vmin solid var(--color_white);
    padding: .5vmin;
    cursor: pointer;
}

.exam>.footer>.questions>.active {
    justify-self: end;
    background-color: var(--sub_main_background_color);
}

.exam>.footer>.questions>button:hover {
    border-color: var(--sub_main_background_color);
}