.popup {
	z-index: 98;
	position: fixed;
	height: 100%;
	width: 100%;
	/* height: 97vh;
	width: 100vw; */
	top: 0;
	left: 0;
	background-color: var(--popup_background_color);
	display: flex;
	align-items: center;
	justify-content: center;
}

