.Chapter {
  /* Add your styles here */
  width: 100%;
  height: calc(100dvh - var(--heightSearchBar));
  padding: var(--factorPlatform);
  position: relative;
  display: grid;
  gap: var(--factorPlatform);
  grid-template-columns: 80% calc(20% - var(--factorPlatform));

  &:has(.Progress.close) {
    grid-template-columns: 100%;
    overflow: auto;

    .Data {
      height: auto;
      overflow: visible;
    }
  }

  @media screen and (max-width: 800px) {
    grid-template-columns: 100%;
  }

  .Data {
    width: 100%;
    height: 100%;
    overflow: auto;

    .title {
      font-size: calc(var(--factorPlatform) * 3);
      font-weight: bolder;
      margin-bottom: calc(var(--factorPlatform) * 1.5);
    }

    .tabs {
      position: sticky;
      top: calc(var(--factorPlatform) * -1);
      z-index: 1;
      background-color: white;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: flex-start;
      align-items: center;
      width: max-content;
      gap: var(--factorPlatform);
      padding: var(--factorPlatform);
      border-radius: calc(var(--factorPlatform) *3);
      margin-bottom: var(--factorPlatform);
      border: 1px solid var(--mainColorPlatForm);

      @media screen and (max-width: 800px) {
        width: 100%;
        justify-content: space-around;
        padding: calc(var(--factorPlatform) *0.5);
      }

      button {
        border: none;
        padding: var(--factorPlatform);
        border-radius: calc(var(--factorPlatform) *3);
        font-size: calc(var(--factorPlatform) *1.5);
        font-weight: bolder;
        background-color: transparent;
        cursor: pointer;
        white-space: nowrap;

        @media screen and (max-width: 800px) {
          padding: calc(var(--factorPlatform) *0.5);
        }
      }

      .active {
        color: white;
        background-color: var(--mainColorPlatForm);
        opacity: 0;
        animation: delayAppearance .2s linear forwards;
      }

      svg {
        position: absolute;
        width: calc(var(--factorPlatform) * 3);
        aspect-ratio: 1 / 1;
        bottom: 0;
        left: calc(var(--factorPlatform) * -1);
        cursor: pointer;
      }
    }

    .tabs.sticky{
      position: sticky;
      top: calc(var(--factorPlatform) * -1);
    }
    .tabs.relative{
      position: relative;
      top: auto;
    }
  }
}