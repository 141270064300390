.alert {
	align-content: center;
	border: var(--border);
	border-color: transparent;
	border-radius: var(--border_radius);
	box-shadow: var(--box_shadow_important);
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: space-between;
	left: 0;
	top: 0;
	transform: translate(calc(50vw - 50%), calc(50vh - 50%));
	max-height: 50%;
	min-height: 20vh;
	min-width: 20vw;
	position: fixed;
	align-items: stretch;
	padding: 0;
	background-color: var(--color_white);
	opacity: 0;
    animation: delayAppearance .2s linear forwards;

}

.alert>.notes {
	overflow: auto;
}
.alert>.notes * {
	font-size: var(--font_size_smoll);
}

.alert-fragment {
	overflow: auto;
}

.alert-fragment>* {
	display: block;
	margin: auto;
	text-align: center;
}

.alert>p {
	padding: 1vmin;
	margin: auto;
	font-size: var(--font_size_smoll);
}

.alert-titel {
	text-align: center;
	margin: 0;
	/* padding: 1vmin; */
	background-color: var(--main_background_color);
	color: var(--color_white);
	font-size: var(--font_size_normal);
	border-radius: var(--border_radius);
}