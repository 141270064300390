.DiscussionsChapter {
  /* Add your styles here */
  border-radius: var(--factorPlatform);
  padding: 0 calc(var(--factorPlatform) * 2);
  width: 100%;

  .discussions {
    padding: calc(var(--factorPlatform) * 2) 0;
    background-color: white;
    width: 100%;
    margin-bottom: var(--factorPlatform);
    border-radius: calc(var(--factorPlatform) * 2);
  }
}