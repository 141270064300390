.SearchBar {
    display: grid;
    grid-template-columns: var(--factor_SearchBar) 1fr var(--factor_SearchBar) var(--factor_SearchBar);
    grid-template-rows: 100%;
    width: 100%;
    height: var(--heightSearchBar);
    align-items: center;
    justify-content: center;
    background-color: white;
    gap: var(--factor_SearchBar);
    /* padding-right: var(--factor_SearchBar); */

}

.SearchBar>.search {
    background-color: var(--backgroundColorPlatformPage);
    display: flex;
    border-radius: calc(var(--factor_SearchBar) / 4);
    height: 70%;
}

.SearchBar>.search>input {
    border: none;
    background-color: var(--backgroundColorPlatformPage);
    width: calc(100% - var(--factor_SearchBar));
    border-radius: calc(var(--factor_SearchBar) / 4);
    height: 100%;
}

.SearchBar>.search>svg,
.SearchBar>svg {
    width: var(--factor_SearchBar);
    height: var(--factor_SearchBar);
    stroke: #353333;
    cursor: pointer;
}

.SearchBar>img {
    max-width: var(--factor_SearchBar);
    max-height: var(--factor_SearchBar);
}

.SearchBar>.search>svg:nth-child(1),
.SearchBar>.search>svg:nth-child(2),
.SearchBar>.search>svg:nth-child(3) {
    cursor: pointer;
}