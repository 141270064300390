.btn-dark {
  cursor: pointer;
  color: #fff;
  /* margin: 1vmin; */
  background-color: var(--main_background_color);
  /* border-color: var(--primary_color); */
  display: inline-block;
  /* font-weight: 400; */
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: .5vmin solid transparent;
  padding: .5vmin 1vmin;
  font-size: var(--font_size_big);
  line-height: 1.5;
  border-radius: var(--border_radius);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  font-size: var(--font_size_smoll);
  text-decoration: none;
  transition: transform 0.3s ease-in-out;
}

.btn-dark:not(.opacity_btn):hover {
  filter: brightness(2);
  transform: scale(var(--scaleOnHover));
}

.btn-dark:not(.opacity_btn):active {
  transform: scale(var(--scaleOnClick));
}

