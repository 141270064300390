.Progress {
  /* Add your styles here */
  background-color: white;
  height: max-content;
  border-radius: var(--factorPlatform);
  padding: var(--factorPlatform);
  box-shadow: var(--box-shadowPlatformCard);
  max-height: calc(100dvh - var(--heightSearchBar) - var(--factorPlatform));
  /* overflow: auto; */
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 800px) {
    position: absolute;
    top: var(--factorPlatform);
    left: 0;
    z-index: 2;
  }

  &.close {
    position: absolute;
    top: var(--factorPlatform);
    left: 0;
  }


  .header {
    display: grid;
    grid-template-columns: auto auto 1fr;
    align-items: center;
    justify-content: center;
    align-content: center;
    justify-items: end;
    cursor: pointer;

    svg {
      width: var(--factor_SearchBar);
      height: var(--factor_SearchBar);
      stroke: #353333;
    }

    p {
      font-size: calc(var(--factorPlatform) * 1.2);
      font-weight: bold;
      white-space: nowrap;
      margin-right: var(--factorPlatform);
    }
  }

  .chaptersName {
    flex: 1 1 auto;
    overflow-y: auto;
    counter-reset: chapterNumber;
    transform: ScaleX(0);
    animation: fadeInRight .2s linear forwards;

    a {
      counter-increment: chapterNumber;
      font-size: calc(var(--factorPlatform)* 2);
      font-weight: bold;
      cursor: pointer;
      margin: 0 var(--factorPlatform) var(--factorPlatform) var(--factorPlatform);
      position: relative;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: flex-start;
      align-items: center;
      gap: var(--factorPlatform);
      text-decoration: none;
    }

    a::before {
      content: '#' counter(chapterNumber);
      font-size: calc(var(--factorPlatform)* 2);
      font-weight: bold;
      width: calc(var(--factorPlatform)* 4);
      aspect-ratio: 1 / 1;
      background-color: #F4F4F4;
      border-radius: 50%;
      display: flex;
      align-content: center;
      justify-content: center;
      align-items: center;
    }

    a.active::before {
      background-color: #9FD616;
      color: white;
    }

    a.complete::before {
      background-color: #299E64;
      color: white;
    }
  }
}