.Platform_sidebar {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    --font_line_sidebar: calc(var(--factorPlatform) * 2);
    --colorActiveSidebar: var(--main_background_color);
    gap: var(--font_line_sidebar);
    background-color: white;
    transform: ScaleX(0);
    animation: fadeInRight .2s linear forwards;
    box-shadow: var(--box-shadowPlatformCard);

    @media screen and (max-width: 800px) {
        position: absolute;
        --font_line_sidebar: calc(var(--factorPlatform) * 2.5);
        top: var(--heightSearchBar);
        height: calc(100dvh - var(--heightSearchBar));
        z-index: 3;
    }
}

.Platform_sidebar>img {
    width: calc(var(--font_line_sidebar) * 5);
    margin-top: var(--font_line_sidebar);
}

.Platform_sidebar>.line {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    padding-left: var(--font_line_sidebar);
}

.Platform_sidebar>.line>svg {
    width: calc(var(--font_line_sidebar) *1.5);
    height: calc(var(--font_line_sidebar) *1.5);
    margin-left: var(--font_line_sidebar);
    margin-right: calc(var(--font_line_sidebar) / 2);
    stroke: #353333;
}

.Platform_sidebar>.line>a {
    font-size: var(--font_line_sidebar);
    text-decoration: none;
    font-weight: bold;
    text-wrap: nowrap;
}

.Platform_sidebar>.line.active {
    border-left: var(--colorActiveSidebar) solid calc(var(--font_line_sidebar) / 3);

}

.Platform_sidebar>.line.active>svg {
    stroke: var(--colorActiveSidebar);

}

.Platform_sidebar>.line.active>a {
    color: var(--colorActiveSidebar);
}