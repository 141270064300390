.Platform {
    --factorPlatform: 1vmin;
    --mainColorPlatForm: #299E64;
    --factor_SearchBar: calc(var(--factorPlatform) * 5);
    --box-shadowPlatformCard: 0 0 var(--factorPlatform) var(--factorPlatform) var(--primary_background_color);
    --heightSearchBar: calc(var(--factor_SearchBar)* 1.5);
    position: relative;
    display: grid;
    grid-template-columns: minmax(10%, auto) 1fr;
    width: 100%;
    height: 100vh;
    height: 100dvh;
    
    @media screen and (max-width: 800px) {
        --factorPlatform: 1vmax;
        --factor_SearchBar: calc(var(--factorPlatform) * 4);
        grid-template-columns: minmax(10%, auto);
    }
}