.CenterHome {
    box-sizing: border-box;
    overflow: auto;
    padding: 1%;
    height: 100%;
    --font_centerHome: calc(var(--factorPlatform) * 1.8);

    @media screen and (max-width: 1280px) {
        --font_centerHome: calc(var(--factorPlatform) * 1.5);
    }

    @media screen and (max-width: 800px) {
        --font_centerHome: calc(var(--factorPlatform) * 1.2);
        overflow: visible;
    }

    .welcome {
        width: 100%;
        background-image: linear-gradient(to left, #49960b, #3d9a2c, #2a9e62);
        display: grid;
        grid-template-columns: auto 1fr;
        justify-items: end;
        overflow: hidden;

        .text {
            padding: var(--font_centerHome);

            p {
                color: white;
            }

            p:nth-child(1) {
                font-size: calc(var(--font_centerHome) * 1.25);
            }

            p:nth-child(2) {
                font-size: var(--font_centerHome);
            }
        }
    }

    .image {
        position: relative;
        width: calc(var(--font_centerHome) * 7);

        @media screen and (max-width: 800px) {
            width: calc(var(--font_centerHome) * 8);
        }

        svg {
            position: absolute;
            max-width: 100%;
            max-height: 100%;
        }

        svg:nth-child(1) {
            top: -20%;
            right: -100%;
            max-width: 150%;
            max-height: 150%;

        }

        svg:nth-child(2) {
            top: 0;
            left: 0;
        }
    }
}