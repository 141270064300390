.RecentNotification {
  /* Add your styles here */
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  gap: var(--factorPlatform);
  margin-bottom: var(--factorPlatform);
  cursor: pointer;

  svg {
    width: 20%;
    max-width: 6vw;
    aspect-ratio: 1 / 1;

    @media screen and (max-width: 800px) {
      max-width: 12vw;
    }
  }

  .data {
    width: 80%;

    p {
      font-size: calc(40% * 2);
      font-weight: bolder;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      max-width: 100%;
      display: block;
    }
  }
}