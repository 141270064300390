.ExamsChapter {
  /* Add your styles here */
  width: 100%;
  /* background-color: white; */
  padding: var(--factorPlatform);
  border-radius: var(--factorPlatform);

  .noExams {
    font-size: calc(var(--factorPlatform) * 2);
    font-weight: bolder;
  }

  .titleExam {
    font-size: var(--font_size_little);
    font-weight: 600;
    margin-bottom: calc(var(--factorPlatform) * 2);
  }
}