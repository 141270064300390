.page {
    width: 100%;

    height: 100dvh;
    display: grid;
    grid-template-rows: auto 1fr auto;
    align-content: space-between;
    justify-items: stretch;
    align-items: normal;
}

.page>.page_title {
    width: 100%;
    position: relative;
}

.page>.page_title>img {
    position: absolute;
    max-width: 7vw;
    max-height: 7vw;
    top: 1vh;
    right: 3vw;
    border-radius: 50%;

    @media screen and (max-width: 800px) {
        max-width: 20vw;
        max-height: 20vw;
    }
}

.page>.page_title>.student_data {
    background-color: var(--main_background_color);
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-around;
    align-items: center;
    padding: 0 10vw 0 2vw;

    @media screen and (max-width: 800px) {
        padding-right: 22vw;
    }
}

.page>.page_title>.academic_data {
    background-color: var(--sub_main_background_color);
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-around;
    align-items: center;
    padding: 0 10vw 0 2vw;

    @media screen and (max-width: 800px) {
        padding-right: 22vw;
    }
}

.page>.page_title p {
    font-weight: bolder;
    font-size: var(--font_size_little);
    color: var(--color_white);
}

.page>.page_title>.academic_data p {
    text-align: center;
}

.page>.page_title>.academic_data p:nth-of-type(2) {
    color: var(--color_black);
}

.page>.content {
    overflow: auto;
}

.page>.content>div>.titles {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
}

.page>.content>.tabs {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-content: space-around;
    justify-content: center;
    align-items: center;
    justify-items: start;
    margin-top: 5vh;
    gap: 3vmax;
    padding: 1vmin;


    @media screen and (max-width: 800px) {
        grid-template-columns: 1fr;
    }
}

.page>.content>.tabs>.tab {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    @media screen and (max-width: 800px) {
        margin-right: auto;
    }
}

.page>.content>.tabs>.tab>img {
    width: calc(var(--font_size_big) * 3);
    height: calc(var(--font_size_big) * 3);
    z-index: 2;
    border-radius: 50%;
}

.page>.content>.tabs>.tab>p {
    white-space: nowrap;
    background-color: var(--primary_background_color);
    color: var(--main_background_color);
    border-radius: calc(var(--font_size_big) * 1.5) 0 0 calc(var(--font_size_big) * 1.5);
    padding: 0 calc(var(--font_size_big) * 2) 0 1vmin;
    margin-right: calc(var(--font_size_big) * -1.5);
    font-size: var(--font_size_big);
    font-weight: bolder;
    height: calc(var(--font_size_big) * 3);
    display: flex;
    align-items: center;
    position: relative;
    z-index: 1;
    user-select: none;

    @media screen and (max-width: 800px) {
        width: calc(70vw - calc(var(--font_size_big) * -1.5));
        padding: 0 calc(var(--font_size_big) * 2) 0 0;
        border-radius: 0;
    }

}





.page>.content>.tabs>.tab>p:hover {
    background-color: var(--sub_main_background_color);
    color: var(--color_white);
}


.page>.footer {
    background-color: var(--main_background_color);
    height: 3vh;
    position: relative;
}

.page>.footer>img {
    position: absolute;
    bottom: 0;
    right: 50%;
    transform: translateX(50%);
    max-width: calc(var(--font_size_big) *2);
    max-height: calc(var(--font_size_big) *2);
    cursor: pointer;
}