.RecentNotifications {
  /* Add your styles here */
  width: 100%;
  background-color: white;
  box-shadow: var(--factorPlatform) var(--factorPlatform) 0 0 var(--primary_background_color);
  border-radius: var(--factorPlatform);
  padding: var(--factorPlatform);
  max-height: calc(100dvh - var(--heightSearchBar));
  overflow: auto;
  padding-left: var(--factorPlatform);


  .title {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;

    p,
    a {
      font-size: calc(50% * 2);
      font-weight: bolder;
      background-color: transparent;
      border: none;
      text-decoration: none;
    }

    a {
      cursor: pointer;
    }
  }
}