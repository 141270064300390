

.loading {
	z-index: 99;
	width: 20vmin;
	height: 20vmin;
	background-color: transparent;
	position: fixed;
	left: calc(50% - 10vmin);
	top: calc(50% - 10vmin);
	border-radius: 50%;
	border: .5vmin solid var(--main_background_color);
	animation: loading 6s infinite;
	border-left-color: transparent;
	animation-timing-function: linear;
}
.cancel {
	z-index: 99;
	display: flex;
	background-color: transparent;
	position: fixed;
	left: 0;
	top: 0;
	transform: translate(calc(50vw - 50% + .25vmin), calc(50vh - 50% + .25vmin));
	animation-timing-function: linear;
	align-content: center;
	flex-wrap: nowrap;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

@keyframes loading {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
