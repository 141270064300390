.table {
    border-collapse: collapse;
    table-layout: auto;
    width: 100%;
}

.thead {
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 3;

}

.thead .tr {
    background-color: var(--sub_main_background_color);

}

.thead .th {
    border: var(--border_table);
    padding: var(--padding_table_cell);
    cursor: pointer;
    position: relative;
    vertical-align: top;
    color: var(--color_white);
}

.tbody>.tr>.td {
    border: var(--border_table);
    text-align: center;
    padding: var(--padding_table_cell);
    position: relative;
}