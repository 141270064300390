:root {
  --main_background_color: #024e30;
  --sub_main_background_color: #f48221;
  --primary_background_color: #e6e6e6;
  --color_white: white;
  --color_black: black;
  --box_shadow_important: 0 .5vmin 1vmin 0 var(--primary_background_color);
  --primary_sub_light_color: #E5F5EF;
  --border: .5vmin solid var(--main_background_color);
  --border_radius: 2vmin;
  --box_shadow_left_important: 0 0vmin 1vmin .1vmin var(--main_background_color);
  --popup_background_color: #75998d73;
  --scaleOnHover: 1.15;
	--scaleOnClick: .9;
  --border_table: .1vmin solid var(--main_background_color);
  --padding_table_cell: .5vmin;
  --dangerous_color: red;
	--success_color: green;
  /* fontes */

  --font_size_big: 3vmin;
  --font_size_normal: 2.3vmin;
  --font_size_little: 2vmin;
  --font_size_smoll: 1.6vmin;
}

@font-face {
  font-family: 'DroidArabicKufiBold';
  src: url('./components/fontes/DroidArabicKufiBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'DroidArabicKufiRegular';
  src: url('./components/fontes/DroidArabicKufiRegular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* The following rules are deprecated. */

@font-face {
  font-family: 'Droid Arabic Kufi';
  src: url('./components/fontes/DroidArabicKufiBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Droid Arabic Kufi';
  src: url('./components/fontes/DroidArabicKufiRegular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
}

#root {
  height: 100%;
  width: 100%;
  direction: rtl;
}

* {
  font-weight: bolder;
  font-family: DroidArabicKufiRegular, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: var(--font_color);
}

div:focus {
  outline: none;
}



@keyframes fadeInRight {
  0% {
    transform: ScaleX(0);
  }

  100% {
    transform: ScaleX(1);
  }
}

@keyframes fadeInTitle {
  0% {
    transform: translateX(-400%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes fadeInLeft {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes delayAppearance {
  0% {
    opacity: 0;
  }

  50% {
    opacity: .5;
  }

  100% {
    opacity: 1;
  }
}


@media screen and (max-width: 800px) {
  :root {
    --font_size_big: 3vmax;
    --font_size_normal: 2vmax;
    --font_size_little: 1.7vmax;
    --font_size_smoll: 1.2vmax;
  }
}