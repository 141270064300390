.ListExams {
  /* Add your styles here */
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(calc(var(--factorPlatform)* 20), 1fr));
  justify-items: stretch;
  gap: var(--factorPlatform);
  align-content: center;
  justify-content: center;
  align-items: stretch;

  .examCard {
    background-color: var(--mainColorPlatForm);
    color: white;
    padding: var(--factorPlatform);
    border-radius: calc(var(--factorPlatform) * 2);
    font-size: calc(var(--factorPlatform) * 2);
    font-weight: bolder;
    cursor: pointer;
    text-align: center;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
  }
}