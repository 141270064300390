.LeftHome {
  /* Add your styles here */
  padding: var(--factorPlatform);
  height: calc(100dvh - var(--heightSearchBar));
  overflow: auto;
  box-sizing: border-box;

  @media screen and (max-width: 800px) {
    overflow: visible;
  }
}