.cards {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-content: space-around;
    justify-content: center;
    align-items: center;
    justify-items: start;
    gap: 3vmax;
    padding: 1vmin;
    width: 80%;
    margin: 5vh auto auto auto;

    @media screen and (max-width: 800px) {
        grid-template-columns: 1fr;
        margin: 5vh auto auto 0;
    }

}


.card {
    cursor: pointer;
    white-space: nowrap;
    background-color: var(--primary_background_color);
    color: var(--main_background_color);
    font-size: var(--font_size_big);
    font-weight: bolder;
    align-items: center;
    user-select: none;
    padding: 1vmin;
    border-radius: calc(var(--font_size_big) * 3);
    text-align: center;

    @media screen and (max-width: 800px) {
        width: calc(70vw - calc(var(--font_size_big) * -1.5));
        border-radius: 0 calc(var(--font_size_big) * 3) calc(var(--font_size_big) * 3) 0;
    }

}


.card.selected,
.card:hover {
    background-color: var(--sub_main_background_color);
    color: var(--color_white);
}