.Card {
  /* Add your styles here */
  display: flex;
  background-color: #fff;
  border-radius: var(--factorPlatform);
  box-shadow: var(--factorPlatform) var(--factorPlatform) 0 0 var(--primary_background_color);
  padding: var(--factorPlatform);
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-end;

  * {
    margin-bottom: var(--factorPlatform);
  }

  .imgContainer {
    width: 100%;
    flex-grow: 1;

    img {
      width: 100%;
    }
  }

  .title {
    font-size: calc(var(--factorPlatform) * 1.5);
    font-weight: bolder;
  }

  .discription {
    font-size: calc(var(--factorPlatform) * 1.2);
    font-weight: 600;
  }

  .professor_time {
    display: flex;
    flex-wrap: wrap;

    .professor,
    .time {
      font-size: calc(var(--factorPlatform) * 1.5);
      font-weight: 700;
      display: flex;
      align-items: center;
      /* justify-content: center; */
      text-align: center;
      white-space: nowrap;
    }

    .professor::before,
    .time::before {
      content: '';
      border-radius: 50%;
      display: inline-block;
      height: calc(var(--factorPlatform) * 3);
      width: calc(var(--factorPlatform) * 3);
      margin: 0 var(--factorPlatform);
      background-color: var(--backgroundColorPlatformPage);
    }
  }

  .attendanceRate {
    width: 100%;
    display: flex;
    gap: var(--factorPlatform);
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-around;
    align-items: center;

    .rate {
      width: 85%;
      height: var(--factorPlatform);
      border-radius: var(--factorPlatform);
    }
  }

  button {
    display: block;
    width: 90%;
    margin-right: auto;
    margin-left: auto;
    background-color: transparent;
    color: green;
    border: calc(var(--factorPlatform) * 0.3) solid green;
    border-radius: calc(var(--factorPlatform) * 1);
    padding: calc(var(--factorPlatform) * 0.5);
    font-size: calc(var(--factorPlatform) * 1.5);
    cursor: pointer;
  }

  button:hover {
    background-color: var(--backgroundColorPlatformPage);
  }

  button:active {
    background-color: green;
    color: white;
  }
}