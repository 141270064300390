:root {
  --font_size_big_login: 4vmin;
  --font_size_normal_login: 3vmin;
  --font_size_smoll_login: 2vmin;
}

.login {
  direction: rtl;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: stretch;
  justify-content: center;
  align-items: stretch;
  width: 100%;
  height: 100vh;
  align-content: stretch;
  font-weight: 700;
}

.login>.login-images {
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  margin: auto auto auto 0;
  position: relative;
  width: 100%;
  height: 100%;
}

.login>.login-images>.login-image {
  max-width: 50vw;
  max-height: 100vh;
}

.login>.login-images>.blur-login {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0.5;
  filter: blur(2vmin);
}

.login>.login-inputs {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  gap: 2vmin;
  align-self: stretch;
  /* background-image: url("./img/logo.jpeg");
  background-repeat: no-repeat;
  background-size: cover; */
  /* background-position-x: right;
  background-position-y: bottom;
  background-size: 16vw; */
}

.login>.login-inputs>.background-inputes-login {
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  /* transform: translate(50%, 50%); */
  margin: auto;
  /* opacity: .5; */
  z-index: -1;
}

.login>.login-inputs>.background-inputes-login>img {
  max-width: 25vw;
  max-height: 45vh;
}

.login>.login-inputs>* {
  margin: 0;
}

.login>.login-inputs>h1 {
  color: var(--main_background_color);
  font-size: var(--font_size_big_login);
}

.login>.login-inputs>.notes>p {
  font-size: var(--font_size_smoll_login);
  font-weight: 200;
  margin: auto;
  width: max-content;
  color: var(--main_background_color);
}

.login>.login-inputs>.input {
  width: 50%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-end;
  align-items: center;
}

.login i {
  z-index: 1;
  font-size: var(--font_size_big_login);
  color: var(--main_background_color);
}

.login>.login-inputs>.input>i {
  margin-left: calc(var(--font_size_normal_login)*-1);
}

.login>.login-inputs>.input>input {
  min-height: 5vh;
  width: 100%;
  padding-right: var(--font_size_big_login);
  border: 0;
  box-sizing: border-box;
  font-size: var(--font_size_normal_login);
  background-color: #f2f4f8;
  color: var(--main_background_color);
}

.login>.login-inputs>.input>input::placeholder {
  opacity: 1;
  color: var(--main_background_color);
  font-size: var(--font_size_smoll_login);
}

.login>.login-inputs>button {
  background-color: var(--main_background_color);
  color: var(--color_white);
  min-height: 5vh;
  width: 50%;
  border: none;
  font-size: var(--font_size_smoll_login);
  cursor: pointer;
}

.login>.login-inputs>img {
  max-width: 8vw;
  max-height: 21vh;
}

@media screen and (max-width: 800px) {
  :root {
    --main_background_color: #024e30;
    --font_size_big_login: 3vmax;
    --font_size_normal_login: 2vmax;
    --font_size_smoll_login: 1vmax;
  }

  .login {
    grid-template-columns: 1fr;
    align-items: start;
  }

  .login>.login-images {
    grid-row: 1;
  }

  .login>.login-images>.login-image {
    max-width: 100vw;
    max-height: 50vh;
    margin: auto;
  }

  .login>.login-inputs>img,
  .login>.login-inputs>.background-inputes-login,
  .login>.login-images>.blur-login {
    display: none;
  }
}