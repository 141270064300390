.ShowQuestion {
  /* Add your styles here */
  width: 100%;
  background-color: white;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--factorPlatform);
  padding: var(--factorPlatform);
  border-radius: var(--factorPlatform);
  margin-top: var(--factorPlatform);

  .question {

    .questionHead,
    .sendAnswers {
      font-size: var(--font_size_little);
      font-weight: 600;
    }

    .sendAnswers {
      color: red;
    }

    .questionImage {
      max-width: 100%;
      max-height: 100%;
    }

    .questionZoomImage {
      max-width: 100vw;
      max-height: 100vh;
      opacity: 0;
      animation: delayAppearance .3s linear forwards;
    }

    @media screen and (max-width: 800px) {
      grid-column: span 2;
    }
  }


  .answers {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    align-content: flex-start;
    justify-content: center;
    flex-wrap: nowrap;

    .answer {
      text-align: right;
      cursor: pointer;
      background-color: var(--backgroundColorPlatformPage);
      color: var(--mainColorPlatForm);
      padding: var(--factorPlatform);
      border-radius: var(--factorPlatform);
      font-size: var(--font_size_little);
      font-weight: 600;
      margin: var(--factorPlatform) 0;
      border: none;
    }

    .answer.selected {
      background-color: var(--mainColorPlatForm);
      color: white;
    }

    @media screen and (max-width: 800px) {
      grid-column: span 2;
    }
  }

  .previous {
    border: none;
    background-color: transparent;
    width: min-content;
    cursor: pointer;
    font-size: var(--font_size_little);
    font-weight: 600;
  }

  .next {
    border: none;
    background-color: var(--mainColorPlatForm);
    color: white;
    width: calc(var(--factorPlatform) * 15);
    cursor: pointer;
    font-size: var(--font_size_little);
    font-weight: 600;
    border-radius: var(--factorPlatform);
    justify-self: end;
  }
}