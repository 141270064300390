.Counter {
  /* Add your styles here */
  width: 100%;
  background-color: white;
  box-shadow: var(--factorPlatform) var(--factorPlatform) 0 0 var(--primary_background_color);
  border-radius: var(--factorPlatform);
  display: flex;
  padding: var(--factorPlatform);
  margin-bottom: var(--factorPlatform);
  flex-wrap: nowrap;
  flex-direction: row;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  gap: var(--factorPlatform);

  .data {
    p {
      font-size: calc(50% * 2);
      font-weight: bolder;
    }
  }

  svg {
    max-width: 20%;
  }
}