.TabQuestions {
  /* Add your styles here */
  background-color: white;
  border-radius: calc(var(--factorPlatform)* 3);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  width: max-content;
  max-width: 100%;
  overflow-x: auto;
  scroll-behavior: smooth;

  .questionTabTitle {
    font-size: var(--font_size_little);
    font-weight: 600;
    padding: calc(var(--factorPlatform) * .5) calc(var(--factorPlatform) * 3);
    cursor: pointer;
    border-radius: calc(var(--factorPlatform) * 2);
    color: var(--mainColorPlatForm);
    border: none;
    background-color: transparent;
  }

  svg {
    height: calc(var(--factorPlatform)* 4);
    max-width: 100%;
    fill: var(--mainColorPlatForm);
    display: flex;
    align-items: 'center'
  }

  .questionTabTitle.active {
    background-color: var(--mainColorPlatForm);
    color: white;
    fill: white;
    opacity: 0;
    animation: delayAppearance .3s linear forwards;
  }

}