.Material {
  height: calc(100dvh - var(--heightSearchBar));
  overflow: auto;

  /* Add your styles here */
  .line {
    display: flex;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    padding-left: var(--factorPlatform);
    margin: var(--factorPlatform) 0;

    svg {
      width: 3%;
      aspect-ratio: 1 / 1;
      margin-left: var(--factorPlatform);
      margin-right: calc(var(--factorPlatform) / 2);
      stroke: #353333;

      @media screen and (max-width: 800px) {
        width: 7%;
      }
    }

    p {
      font-size: 100%;
      font-weight: bold;
      text-wrap: nowrap;
    }

  }

  .cards {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: calc(var(--factorPlatform) * 2);

    @media screen and (max-width: 1280px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media screen and (max-width: 800px) {
      grid-template-columns: 1fr;
    }
  }
}