.RecentLecture {
  margin-top: var(--factorPlatform);

  /* Add your styles here */
  .noRecentLecture {
    font-size: calc(var(--factorPlatform) * 2);
    font-weight: bolder;
    background-color: white;
    border-radius: var(--factorPlatform);
    color: red;
  }

  .title {
    font-size: calc(var(--factorPlatform) * 3);
    font-weight: bolder;
  }

  .cards {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: calc(var(--factorPlatform) * 2);

    @media screen and (max-width: 1280px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media screen and (max-width: 800px) {
      grid-template-columns: 1fr;
    }
  }
}