.form_service {
    margin: auto;
    margin-top: 2vh;
    box-shadow: var(--box_shadow_important);
    background-color: var(--color_white);
    opacity: 0;
    animation: delayAppearance .2s linear forwards;
    width: fit-content;
    padding: 2vmin;
    min-width: 30vw;
    border: var(--border);
    border-radius: var(--border_radius);
}


.form_service>p {
    color: var(--main_background_color);
}

.form_service>input {
    border: var(--border);
    border-radius: var(--border_radius);
    padding: 2vmin;
    width: 50vw;
    box-sizing: border-box;
    transition: border-color 0.3s ease-in-out;
}
.form_service>input:focus {
    border-color: var(--sub_main_background_color);
    outline: none;
}

@keyframes borderColorChange {
    0% {
        border: var(--border); /* Initial color */
    }
    100% {
        border-color: var(--sub_main_background_color); /* Focus color */
    }
}

.form_service>.btns_service {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;
    align-content: center;
    margin-top: 1vh;
}

.form_service>.btns_service>button {
    border: var(--border);
    border-radius: var(--border_radius);
    padding: .5vmin;
    background-color: var(--main_background_color);
    color: var(--color_white);
    cursor: pointer;
}



.form_service>.btns_service>.send_service {
    background-color: var(--sub_main_background_color);
    border-color: var(--sub_main_background_color);
    opacity: 0;
    animation: delayAppearance .2s linear forwards;
}