.AddComment {
  /* Add your styles here */
  width: 100%;
  position: sticky;
  bottom: 0;
  background-color: white;
  padding: 0 calc(var(--factorPlatform) * 2) calc(var(--factorPlatform) * 2) calc(var(--factorPlatform) * 2);
  box-shadow: var(--box-shadowPlatformCard);
  border-radius: calc(var(--factorPlatform) * 2);

  .HeadAddComment {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr auto auto;
    align-items: center;
    gap: var(--factorPlatform);
    margin-bottom: var(--factorPlatform);

    .titleAddComment,
    .ReplayAddComment {
      font-size: calc(var(--factorPlatform) * 2);
      font-weight: bolder;
    }

    .ReplayAddComment {
      color: var(--mainColorPlatForm);
    }

    svg {
      width: calc(var(--factorPlatform) * 3);
      aspect-ratio: 1/1;
      cursor: pointer;
    }
  }

  .inputComment {
    width: 100%;
    height: calc(var(--factorPlatform) * 6);
    border: none;
    border-radius: var(--factorPlatform);
    padding: var(--factorPlatform);
    margin-bottom: var(--factorPlatform);
    background-color: var(--backgroundColorPlatformPage);
    font-size: calc(var(--factorPlatform)* 1.5);
  }

  .btnAddComment {
    height: calc(var(--factorPlatform) * 4);
   aspect-ratio: 5/1;
    border: none;
    border-radius: var(--factorPlatform);
    background-color: var(--mainColorPlatForm);
    color: white;
    font-size: calc(var(--factorPlatform) * 2);
    cursor: pointer;
  }
}