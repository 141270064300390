.sidebar {
  position: fixed;
  z-index: 4;
  width: fit-content;
  flex-grow: 1;
}

.sidebar>.collapse {
  position: fixed;
  top: 1vh;
  font-size: var(--font_size_big);
  color: var(--main_background_color);
  margin: 0;
  padding: 0;
  z-index: 2;
  cursor: pointer;
}

.sidebar>.collapse.right {
  position: absolute;
  left: 2vw;
}

.sidebar>.collapse.left {
  position: fixed;
  right: 1vw;
  transform: scaleX(-1);
  color: var(--sub_main_background_color);
  background-color: var(--main_background_color);
  padding: 1vmin;
  border-radius: 0 var(--border_radius) var(--border_radius) 0;
  box-shadow: 0 .5vmin 1vmin 0 var(--primary_background_color);
}

.sidebar>.menu {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: flex-start;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1vh;
  /* width: 25vw; */
  overflow: auto;
  background-color: var(--color_white);
  padding: 5vh 2vw 3vh 2vw;
  user-select: none;
  height: 100dvh;
  box-sizing: border-box;
  margin-left: 1vw;
  min-width: 17vw;
  transform: translateX(100%);
  animation: fadeInRight .2s linear forwards;
  box-shadow: var(--box_shadow_left_important);
}



.sidebar>.menu>.tab {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  @media screen and (max-width: 800px) {
    margin-right: auto;
  }
}

.sidebar>.menu>.tab>img {
  width: calc(var(--font_size_big) * 3);
  height: calc(var(--font_size_big) * 3);
  z-index: 2;
  border-radius: 50%;
}

.sidebar>.menu>.tab>p {
  white-space: nowrap;
  background-color: var(--primary_background_color);
  color: var(--main_background_color);
  padding: 0 calc(var(--font_size_big) * 2) 0 1vmin;
  margin-right: calc(var(--font_size_big) * -1.5);
  font-size: var(--font_size_big);
  font-weight: bolder;
  height: calc(var(--font_size_big) * 3);
  display: flex;
  align-items: center;
  user-select: none;
  border-radius: calc(var(--font_size_big) * 1.5) 0 0 calc(var(--font_size_big) * 1.5);
  @media screen and (max-width: 800px) {
    width: calc(70vw - calc(var(--font_size_big) * -1.5));
  }

}




.sidebar>.menu>.tab>p:hover {
  background-color: var(--sub_main_background_color);
  color: var(--color_white);
}


.sidebar>.menu>.tab>p.selected {
  background-color: var(--sub_main_background_color);
  color: var(--color_white);
  opacity: 0.5;
}

