.LecturesChapter {
  box-shadow: var(--box-shadowPlatformCard);

  /* Add your styles here */
  .noResources {
    font-size: calc(var(--factorPlatform) * 2);
    font-weight: bolder;
  }

  .resources {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: var(--factorPlatform);
    justify-content: flex-start;
    margin-bottom: var(--factorPlatform);
    padding: var(--factorPlatform);
    width: -webkit-max-content;
    width: max-content;
    max-width: 100%;
    overflow: auto;

    .resource {
      background-color: white;
      border-radius: var(--factorPlatform);
      padding: var(--factorPlatform);
      border: 1px solid var(--mainColorPlatForm);
      border-radius: calc(var(--factorPlatform)* 3);
      color: var(--mainColorPlatForm);
      cursor: pointer;
      font-size: calc(var(--factorPlatform) * 2);
      font-weight: bolder;
    }

    .active {
      animation: delayAppearance .2s linear forwards;
      background-color: var(--mainColorPlatForm);
      color: #fff;
    }
  }

  .cardLecture {
    background-color: white;
    border-radius: var(--factorPlatform);
    padding: var(--factorPlatform);
    width: 100%;
    margin-bottom: var(--factorPlatform);

    .title {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: space-between;
      align-items: center;

      p,
      a {
        font-size: calc(var(--factorPlatform) * 2);
        font-weight: bolder;
        text-decoration: none;
      }
    }

    iframe {
      width: 100%;
      aspect-ratio: 16 / 9;
      border-radius: var(--factorPlatform);
    }
  }
}