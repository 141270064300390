.AttendanceRate {
  /* Add your styles here */
  width: 100%;
  background-color: white;
  box-shadow: var(--factorPlatform) var(--factorPlatform) 0 0 var(--primary_background_color);
  border-radius: var(--factorPlatform);
  display: flex;
  padding: var(--factorPlatform);
  margin-bottom: var(--factorPlatform);
  flex-wrap: nowrap;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  gap: var(--factorPlatform);


  p {
    font-size: calc(60% * 2);
    font-weight: bolder;
  }

  .progress-circle {
    width: 50%;
    aspect-ratio: 1 / 1;
    position: relative;
    border-radius: 50%;

    @media screen and (max-width: 800px) {
      width: 40%;
    }

    span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: calc(var(--factorPlatform) * 3);
      font-weight: bold;
      color: #4caf50;
      z-index: 2;
    }
  }

  .progress-circle::before {
    content: "";
    position: absolute;
    top: 15%;
    left: 15%;
    width: 70%;
    aspect-ratio: 1 / 1;
    background: white;
    border-radius: 50%;
    z-index: 1;
  }

}