.DiscussionChapter {

  /* Add your styles here */
  margin-right: calc(var(--factorPlatform) * .5);

  .DiscussionChapter {
    margin-right: calc(var(--factorPlatform) * 5);
  }

  .comment {
    background-color: var(--backgroundColorPlatformPage);
    margin: var(--factorPlatform);
    padding: var(--factorPlatform) 0 var(--factorPlatform) var(--factorPlatform);
    border-radius: var(--factorPlatform);
    box-sizing: border-box;

    .header {
      display: grid;
      grid-template-columns: auto auto 1fr auto;
      justify-items: center;
      align-items: center;
      gap: var(--factorPlatform);
      padding-right: var(--factorPlatform);

      .name {
        font-size: calc(var(--factorPlatform) * 2);
        font-weight: bolder;
        align-self: end;
        justify-self: start;
      }


      .StudentSvg,
      .ReplaySvg {
        aspect-ratio: 65/59;
        grid-row: span 2;
      }

      .StudentSvg {
        width: calc(var(--factorPlatform) * 10);
        align-self: start;
      }

      .ReplaySvg {
        width: calc(var(--factorPlatform) * 3);
        justify-self: end;
        fill: var(--mainColorPlatForm);
        cursor: pointer;
      }

      .replay {
        color: var(--mainColorPlatForm);
        font-size: calc(var(--factorPlatform) * 1.5);
        font-weight: bolder;
        grid-row: span 2;
        cursor: pointer;
      }

      .date {
        font-size: calc(var(--factorPlatform) * 1.3);
        font-weight: 800;
        align-self: start;
        justify-self: start;

      }

      @media screen and (max-width: 800px) {
        .StudentSvg {
          width: calc(var(--factorPlatform) * 5);
        }

        .ReplaySvg {
          width: calc(var(--factorPlatform) * 1.5);
        }
      }
    }

    .content {
      padding-right: calc(var(--factorPlatform) * 10 + var(--factorPlatform));
      font-size: calc(var(--factorPlatform) * 1.3);
      font-weight: 800;
      /* line-height: calc(var(--factorPlatform) * 2); */
      margin-top: var(--factorPlatform);

      @media screen and (max-width: 800px) {
        padding-right: calc(var(--factorPlatform) * 5 + var(--factorPlatform));
      }
    }
  }

  .comment.selected {
    border: calc(var(--factorPlatform) * .5) solid var(--mainColorPlatForm);
    box-shadow: var(--box-shadowPlatformCard);
  }
}