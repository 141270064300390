.HomePlatform {
    width: 100%;
    height: calc(100dvh - var(--heightSearchBar));
    display: grid;
    grid-template-columns: 75% 25%;
    flex: 1;

    @media screen and (max-width: 800px) {
        grid-template-columns: 100%;
        overflow: auto;
    }
}